<template>
    <div v-if="show">
        <div class="ant-image-preview-root">
            <div class="ant-image-preview-mask"></div>
            <div tabindex="-1" class="ant-image-preview-wrap " role="dialog">
                <div role="document" class="ant-image-preview">
                    <div tabindex="0" aria-hidden="true" style="width: 0px; height: 0px; overflow: hidden;"></div>
                    <div class="ant-image-preview-content"><!----><!---->
                    <div class="ant-image-preview-body">
                        <ul style="background:none" class="ant-image-preview-operations">
                            <li @click="close" class="ant-image-preview-operations-operation">
                                <span style="color:black;font-size:30px" role="img" aria-label="close" class="anticon anticon-close ant-image-preview-operations-icon">
                                   <CloseCircleOutlined />
                                </span>
                            </li>

                        </ul>
                        <div class="ant-image-preview-img-wrapper" style="transform: translate3d(0px, 0px, 0px);">
                            <img class="ant-image-preview-img" :src="src" style="transform: scale3d(1, 1, 1) rotate(0deg);">
                        </div>
                        </div><!---->
                    </div>
                    <div tabindex="0" aria-hidden="true" style="width: 0px; height: 0px; overflow: hidden;"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import { CloseCircleOutlined} from '@ant-design/icons-vue';

export default {
     components:{CloseCircleOutlined},
    props:{
        show:Boolean,
        src:String,
    },
    mounted(){
    },
    methods:{
        close(){
            this.$emit('update:show', false)
        },
    }
}
</script>