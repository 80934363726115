<template>
    
    <div>
        <myHead/>
        <div style="padding:20px">
            <a-row  style="width:100%; ">
                <a-col flex="600px">
                    <a-card >
                        <div>
                            <h3><WalletOutlined /> 钱包ID<a-tag style="margin-left:20px"  color="#2db7f5">{{ memberAccount.WalletId }}</a-tag> </h3> 
                            <div style="float:right;margin-top:-35px">  <a-button @click="zhuanAction" type="primary">转账</a-button></div>
                        </div>
                         
                         <a-divider  />
                         <div style="height:156px">
                            <a-row >
                                <a-col :span="12">
                                    <a-statistic title="积分账户" :precision="2" :value="memberAccount.Balance" />
                                </a-col>
                                <a-col :span="12">
                                    <a-statistic title="赔付账户" :precision="2" :value="memberAccount.Payout" />
                                </a-col>
                            </a-row>
                            <div v-if="memberAccount.Advance>0" style="letter-spacing:1px;line-height:25px;">
                                <div style="font-size:12px;">平台已为您开通 
                                    <span style="color:#F56C6C">{{(memberAccount.Advance).toFixed(2)}}</span> 元垫付额度</div>
                                <div style="font-size:12px;" >垫付额度代表您账户最大可透支金额，使用垫付额度须在次日返款</div>
                            </div>
                            <a-row :gutter="30" style="margin-top:-15px">
                                <a-col :span="12">
                                    <a-button @click="topUpAction" class="loginBtn" >
                                        充值
                                    </a-button>
                                </a-col>
                                <a-col :span="12">
                                    <a-button  @click="withdrawAction"  class="otherBtn" >
                                    提现
                                </a-button>
                                </a-col>
                            </a-row>
                        </div>
                    </a-card>
                </a-col>
                <a-col flex="auto"   >
                    <a-card  style="margin-left:20px">
                        <h3> <BarChartOutlined /> 佣金概览</h3>
                         <a-divider  />
                             <div id="myChart" :style="'width: '+dataWidth+'px; height: 156px;'">
                            </div>
                    </a-card>
                </a-col>
            </a-row>
            <a-row style="padding-top:30px" >
                <a-col :span="24">
                     <a-card style="width:100%">
                        <a-tabs v-model:activeKey="activeKey" tab-position="left" style="width:100%">
                            <a-tab-pane key="1"  >
                                <template #tab>
                                    <span>
                                    <PayCircleOutlined />
                                     充值记录
                                    </span>
                                </template>
                                 <a-table 
                                    :rowKey="record => record.Id" 
                                    :columns="columns"  
                                    :loading="isLoading"
                                    :data-source="topUpList"
                                    @change="handleTableChange"
                                    :pagination="pagination">
                                    <template #status="{ text: Status }">
                                        <span>
                                            <a-tag v-if="Status==3" color="#F56C6C">失败</a-tag>
                                            <a-tag v-else-if="Status==1" color="#E6A23C">审核中</a-tag>
                                            <a-tag v-else color="#67C23A">成功</a-tag>
                                        </span>
                                    </template>
                                    <template #moneyText="{ text }">
                                        <span style="font-weight:500">
                                            ￥{{text}}
                                        </span>
                                    </template>
                                    <template #transferor="{ record  }">
                                        <span>
 
                                            <a @click="preview(record)"> <PictureOutlined /> 点击查看</a>
                                        </span>
                                    </template>
                                </a-table>
                            </a-tab-pane>
                            <a-tab-pane key="2" >
                                <template #tab>
                                    <span>
                                    <TransactionOutlined />
                                     提现记录
                                    </span>
                                </template>
                                 <a-table 
                                    :rowKey="record => record.Id" 
                                    :columns="columnsWithdraw"  
                                    :loading="isLoading"
                                    :data-source="withdrawList"
                                    @change="handleTableChangeWithdraw"
                                    :pagination="paginationWithdraw">
                                    <template #moneyText="{ text }">
                                        <span style="font-weight:500">
                                            ￥{{text}}
                                        </span>
                                    </template>
                                    <template #status="{ text: Status }">
                                        <span>
                                            <a-tag v-if="Status==3" color="#F56C6C">失败</a-tag>
                                            <a-tag v-else-if="Status==1" color="#E6A23C">审核中</a-tag>
                                            <a-tag v-else color="#67C23A">成功</a-tag>
                                        </span>
                                    </template>
                                </a-table>
                            </a-tab-pane>
                        </a-tabs>
                    </a-card>
                </a-col>
            </a-row>
        </div>
         <myFooter/>
         <myPreview   v-model:show="isPreview" :src="imgSrc" />

    <a-drawer
    title="充值"
    :width="720"
    :visible="topUpVisible"
    :body-style="{ paddingBottom: '80px' }"
    @close="topUpVisible=false"
  >
  <a-steps :current="current" size="small">
    <a-step title="填写金额" />
    <a-step title="汇款提交" />
  </a-steps>
<div  v-if="current==0" >
    <div style="text-align:center;margin-top:50px">
    您需要充值的金额：
     <a-input-number
    :min="0"
    style="width:120px"
    v-model:value="rechargeMoney"
    :max="50000"
    :formatter="value => `￥${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
    :parser="value => value.replace(/\￥\s?|(,*)/g, '')"/>
  </div>

 <div style="text-align:center;margin-top:20px">
    <a-button type="primary" @click="next" shape="round" >下一步</a-button>
 </div>
 <a-alert
 style="margin-top:20px"
    message="注意事项"
    type="info"
    show-icon
  >
    <template #description>
        <div style="letter-spacing:3px;margin:5px">1、单笔充值金额不能超过49999元</div>
        <div style="letter-spacing:3px;margin:5px">2、必须严格根据系统提供的账户名及账号进行转账</div>
        <div style="letter-spacing:3px;margin:5px">3、审核时间8:00-23:00，审核不及时请联系客服</div>
    </template>
  </a-alert>
</div>
<div v-else-if="current==1">
    <div style="text-align:center;margin-top:20px;letter-spacing:2px;">
        <div>
            <b >请往以下任意账户转账 <span style="color:red">{{rechargeMoney.toFixed(2)}}</span> 元</b>
        </div>
    </div>
    <a-card hoverable v-if="rechargeModel.OneAlipayName"  style="width: 100%;margin-top:20px">
        <a-row>
            <a-col :span="4">
                <alipay-outlined /> 支付宝
            </a-col>
            <a-col :span="10">
                {{rechargeModel.OneAlipayName}}
            </a-col>
            <a-col :span="10">
                {{rechargeModel.OneAlipayAccount}}
            </a-col>
        </a-row>
    </a-card>
    <a-card hoverable v-if="rechargeModel.TwoAlipayName"  style="width: 100%;margin-top:20px">
        <a-row>
            <a-col :span="4">
                <alipay-outlined /> 支付宝
            </a-col>
            <a-col :span="10">
                {{rechargeModel.TwoAlipayName}}
            </a-col>
            <a-col :span="10">
                {{rechargeModel.TwoAlipayAccount}}
            </a-col>
        </a-row>
    </a-card>
    <a-card hoverable v-if="rechargeModel.OneBankName" style="width: 100%;margin-top:20px">
        <a-row>
            <a-col :span="4">
                <credit-card-outlined /> 银行卡
            </a-col>
            <a-col :span="4">
                {{rechargeModel.OneBankName}}
            </a-col>
            <a-col :span="8">
                {{rechargeModel.OneBank}}
            </a-col>
            <a-col :span="8">
                {{rechargeModel.OneBankAaccount}}
            </a-col>
        </a-row>
    </a-card>
    <a-card hoverable v-if="rechargeModel.TwoBankName" style="width: 100%;margin-top:20px">
        <a-row>
            <a-col :span="4">
                <credit-card-outlined /> 银行卡
            </a-col>
            <a-col :span="4">
                {{rechargeModel.TwoBankName}}
            </a-col>
            <a-col :span="8">
                {{rechargeModel.TwoBank}}
            </a-col>
            <a-col :span="8">
                {{rechargeModel.TwoBankAaccount}}
            </a-col>
        </a-row>
    </a-card>

    <a-alert
    style="margin-top:20px"
    message="请勿刷新此页面"
    type="info"
    show-icon
  />
    <div style="text-align:center;margin-top:20px">
        <a-upload
            style="text-align:center"
            accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
            v-model:file-list="fileList"
            :action="baseUrl+'/Account/PushImg'"
            list-type="picture-card"
            >
            <div v-if="fileList.length==0">
                <plus-outlined />
                <div style="margin-top: 8px">上传截图</div>
            </div>
        </a-upload>
        <div style="text-align:center;margin-top:20px;">
            <a-button   type="primary" @click="rechargeAction" shape="round" style="letter-spacing:2px;" >提交</a-button>
        </div>
    </div>
</div>
  </a-drawer>


    <a-drawer
    title="提现"
    :width="720"
    :visible="withdrawVisible"
    :body-style="{ paddingBottom: '80px' }"
    @close="withdrawVisible=false"
  >
    <a-form :model="withdrawForm" ref="withdrawRef" :rules="withdrawRules" layout="vertical">
        <a-row :gutter="16">
            <a-col :span="12">
                <a-form-item label="开户名" name="AccountName">
                    <a-input v-model:value="withdrawForm.AccountName" placeholder="请输入开户名"/>
                </a-form-item>
            </a-col>
            <a-col :span="12">
            <a-form-item label="提现金额" name="Money">
                        <a-input-number
                        :min="1"
                        style="width:100%"
                        v-model:value="withdrawForm.Money"
                        :formatter="value => `￥${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                        :parser="value => value.replace(/\￥\s?|(,*)/g, '')"/>
            </a-form-item>
            </a-col>
        </a-row>
        <a-row :gutter="16">
            <a-col :span="12">
                <a-form-item label="银行名称" name="AccountBankName">
                    <a-input v-model:value="withdrawForm.AccountBankName" placeholder="请输入银行名称"/>
                </a-form-item>
            </a-col>
            <a-col :span="12">
            <a-form-item label="银行卡号" name="CardNo">
                    <a-input v-model:value="withdrawForm.CardNo" placeholder="请输入银行卡号"/>
            </a-form-item>
            </a-col>
        </a-row>
    </a-form>
    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 1,
      }"
    >
      <a-button style="margin-right: 8px" @click="withdrawVisible=false">取消</a-button>
      <a-button type="primary" @click="withdrawSumbit">确定</a-button>
    </div>
  </a-drawer>
    </div>

    <a-modal :confirmLoading="isLoading"  v-model:visible="zhuanVisible" title="转账"  style="width:400px" @ok="zhuanOk"  ok-text="确认" cancel-text="取消">
            <a-alert message="请先查询对方信息核对后转账" type="info" show-icon />
            <a-input-search
            :loading="isLoading"
            style="margin-top:20px;"
      v-model:value="zhuanMondel.walletId"
      placeholder="请输入对方钱包Id"
      enter-button
      @search="onSearch"
    />
            <a-row style="margin-top:20px;margin-bottom: 20px;">
                <a-col :span="12">
                    <a-tag>名称</a-tag>
                    {{ target.name }}
                </a-col>
 
                <a-col :span="12">
                    <a-tag>账号</a-tag>
                    {{ target.phone }}
                </a-col>

            </a-row>

            <a-input-number :min="1" :max="999999" v-model:value="zhuanMondel.money" prefix="￥" style="width: 100%;">
                <template #addonBefore>
                    转账金额
                </template>
            </a-input-number>
        </a-modal>


</template>
<script>
import myHead from '../components/myHead'
import myFooter from '../components/myFooter'
 import myPreview from '../components/myPreview'

import {accountGetAccount,accountGetCommission,topUpGet,withdrawGet,topUp,withdrawNew,uri,getCollection,accountTransfer,accountGetTarget } from '@/request.js'


import { message } from 'ant-design-vue';
// AlipayOutlined,WechatOutlined,CreditCardOutlined
import { WalletOutlined,PayCircleOutlined,BarChartOutlined,TransactionOutlined,PictureOutlined,AlipayOutlined,CreditCardOutlined,PlusOutlined } from '@ant-design/icons-vue';

export default {
    components:{ myHead,myFooter,WalletOutlined,PayCircleOutlined,BarChartOutlined,AlipayOutlined,CreditCardOutlined,PlusOutlined,
    TransactionOutlined,PictureOutlined,myPreview},
    data(){
        return{
            fileList:[],
            rechargeIndex:0,
            rechargeModel:{},
            rechargeMoney:0,
            current:0,
            dataWidth:0,
            withdrawVisible:false,
            uploading:false,
            baseUrl:uri,
            topUpType:1,
            topUpVisible:false,
            isPreview:false,
            isLoading:false,
            imgSrc:"",
            memberAccount:{},
            activeKey: '1',
            rechargeTab:{
                activeKey:"1",
            },
            commissionData: {},
            topUpTotal:0,
            topUpList:[],
            withdrawList:[],
            columns:[
                {
                    title: '提交时间',
                    dataIndex: 'CreateTime',
                    key: 'CreateTime',
                },
                {
                    title: '金额',
                    dataIndex: 'MoneyText',
                    key: 'MoneyText',
                    slots: { customRender: 'moneyText' },
                },
                {
                    title: '转账凭证',
                    dataIndex: 'Transferor',
                    key: 'Transferor',
                    slots: { customRender: 'transferor' },
                },
                {
                    title: '状态',
                    dataIndex: 'Status',
                    key: 'Status',
                    slots: { customRender: 'status' },
                },
                
            ],
            columnsWithdraw:[
                {
                    title: '提交时间',
                    dataIndex: 'CreateTime',
                    key: 'CreateTime',
                },
                {
                    title: '开户名',
                    dataIndex: 'AccountName',
                    key: 'AccountName',
                },
                {
                    title: '开户行',
                    dataIndex: 'AccountBankName',
                    key: 'AccountBankName',
                },
                                {
                    title: '卡号',
                    dataIndex: 'CardNo',
                    key: 'CardNo',
                },
                {
                    title: '金额',
                    dataIndex: 'MoneyText',
                    key: 'MoneyText',
                    slots: { customRender: 'moneyText' },
                },
                {
                    title: '状态',
                    dataIndex: 'Status',
                    key: 'Status',
                    slots: { customRender: 'status' },
                },
            ],
            pagination: {
                total: 0,
                pageSize: 5,//每页中显示10条数据
                showSizeChanger: false,
                pageSizeOptions: ["5", "10", "20", "50"],//每页中显示的数据
                showTotal: total => `共 ${total} 条`,  //分页中显示总的数据
            },
            paginationWithdraw: {
                total: 0,
                pageSize: 5,//每页中显示10条数据
                showSizeChanger: false,
                pageSizeOptions: ["5", "10", "20", "50"],//每页中显示的数据
                showTotal: total => `共 ${total} 条`,  //分页中显示总的数据
            },
            topUpForm:{
                Money:0,
                ImgSrc:"",
                OutsiderId:0,
                LeaderId:0,
            },
            topUpRules:{
                Transferor:[{required:true,message:'请填写账号名称/开户名'}],
                Money:[{required:true,message:'请填写充值金额',type:'number'}],
                ImgSrc:[{required:true,message:'请上传转账凭证截图'}],
            },
            withdrawForm:{
                Money:0,
                AccountBankName:"",
                AccountName:"",
                CardNo:"",
            },
            withdrawRules:{
                AccountBankName:[{required:true,message:'请填写银行名称'}],
                Money:[{required:true,message:'请填写充值金额',type:'number'}],
                AccountName:[{required:true,message:'请填写银行卡开户名'}],
                CardNo:[{required:true,message:'请填写银行卡卡号'}],
            },
            zhuanVisible:false,
            zhuanMondel:{
                walletId:"",
                money:1
            },
            target:{},
        }
    },
    created(){
        accountGetAccount({},(res)=>{
            this.memberAccount=res.Result;
        })
        this.dataWidth=document.body.clientWidth-720-20;
    },
    mounted(){
        if(this.$route.query.topUpVisible){
            this.topUpVisible=true;
        }
        let myChart = this.$root.echarts.init(
            document.getElementById("myChart")
        );
        accountGetCommission({},(res)=>{
             myChart.setOption(res.Result);
        });
        this.topUpGetAction(1);
         this.withdrawGetAction(1);
    },
    methods:{
        rechargeAction(){
            if(this.fileList.length==0){
                message.error("请上传转账截图");
                return;
            }
            topUp({
                Money:this.rechargeMoney,
                ImgSrc:this.fileList[0].response.Message,
                OutsiderId:this.rechargeModel.OutsiderId,
                LeaderId:this.rechargeModel.LeaderId
            },(res)=>{
                if(res.IsSuccess){
                    if(this.topUpVisible){
                        this.topUpGetAction(1);
                        this.topUpVisible=false;
                        this.topUpForm={
                            Money:0,
                            ImgSrc:"",
                            LeaderId:0,
                            OutsiderId:0,
                        }
                        this.rechargeMoney=0;
                        this.fileList=[];
                        this.rechargeModel={};
                        this.current=0;
                        message.success("提交成功，请等待审核");
                    }
                }else{
                    message.error(res.Message);
                }
            })
        },
        zhuanOk(){
            if(this.zhuanMondel.walletId.length<5){
                message.error("请输入正确的钱包ID")
            }else{
                if(this.target){
                    this.isLoading=true;
                    accountTransfer(this.zhuanMondel,(res)=>{
                        if(res.IsSuccess){
                            this.zhuanVisible=false;
                            accountGetAccount({},(res)=>{
                                this.memberAccount=res.Result;
                            })
                            message.success("转账成功")
                        }else{
                            message.error(res.Message);
                        }
                        this.isLoading=false;
                    })
                }else{
                    message.error("请先查询对方信息")
                }
            }
        },
        zhuanAction(){
            this.zhuanVisible=true;
            this.target={};
        },
        onSearch(){
            if(this.zhuanMondel.walletId.length<5){
                message.error("请输入正确的钱包ID")
            }else{
                this.isLoading=true;
                accountGetTarget({walletId:this.zhuanMondel.walletId},(res)=>{
                    if(res.IsSuccess){
                        this.target.name=res.Result.Name;
                        this.target.phone=res.Result.Phone;
                    }else{
                        message.error(res.Message);
                    }
                    this.isLoading=false;
                })
            }
        },
        next(){
            if(this.rechargeMoney<1){
                message.error("至少充值 1 积分");
                return;
            }
            if(this.rechargeMoney>50000){
                message.error("单笔不能大于 5 万");
                return;
            }
            getCollection({money:this.rechargeMoney},(res)=>{
                this.rechargeModel=res.Result;
                this.current=1;
            })
        },
        withdrawSumbit(){
            this.$refs.withdrawRef
            .validate()
            .then(() => {
                    withdrawNew(this.withdrawForm,(res)=>{
                        if(res.IsSuccess){
                           this.withdrawGetAction(1);
                           this.withdrawVisible=false;
                            message.success("提交成功，请等待审核");
                        }else{
                            message.error(res.Message);
                        }
                    })
            })
            .catch(error => {
                 console.log('error', error);
            });
        },
        withdrawAction(){
            this.withdrawForm={
                Money:0,
                AccountBankName:"",
                AccountName:"",
                CardNo:"",
            }
            this.withdrawVisible=true;

        },
        topUpAction(){
            this.topUpVisible=true;
        },
        preview(record){
            this.imgSrc=record.ImgUrl;
            this.isPreview=true;
        },
        withdrawGetAction(pageIndex){
            this.isLoading=true;
            withdrawGet({pIndex:pageIndex,pSize:this.pagination.pageSize},(res)=>{
                this.withdrawList=res.Result;
                this.paginationWithdraw.total=res.TotalCount;
                this.isLoading=false;
            })
        },
        topUpGetAction(pageIndex){
            this.isLoading=true;
            topUpGet({pIndex:pageIndex,pSize:this.pagination.pageSize},(res)=>{
                this.topUpList=res.Result;
                this.pagination.total=res.TotalCount;
                this.isLoading=false;
            })
        },
        handleChange(info){
            if(info.file.response){
                var res=info.file.response;
                if(res.IsSuccess){
                    this.uploading=false;
                    this.topUpForm.ImgSrc= res.Message;
                }else{
                    message.error(res.Message);
                }
            }
        },
        handleTableChange(e){
            this.topUpGetAction(e.current)
        },
        handleTableChangeWithdraw(e){
            this.withdrawGetAction(e.current);
        },
    }
}
</script>
<style scoped>

</style>